import { Footer, Header, ProjectsList } from 'sections'
import { Layout, SEO } from 'shared/components'

interface ProjectsPageProps {
  location: Location
}

const ProjectsPage = ({ location }: ProjectsPageProps) => {
  const searchParams = new URLSearchParams(location.search)
  return (
    <Layout
      topBar={<Header />}
      footer={<Footer />}
      seo={<SEO title="Projekty" />}
    >
      <ProjectsList query={searchParams.get('q')} />
    </Layout>
  )
}

export default ProjectsPage
